(function($) {
    $(document).ready(function () {
        const toTop = $('#to-top');
        const sideNav = $('#side-nav');
        const sideNavMarker = $('#side-nav-marker');
        const modHomeNumbers = $('.mod-home-numbers');
        var homeCountedUp = false;
        const header = $('#header');

        toTop.click(function () {
            $('html, body').animate({ scrollTop: 0 }, 300);
        });

        const style = 'background-color: white; color: #ff4200; font-family: "Lora", sans-serif; font-size: 2em; font-weight: bold; padding: 10px;'
        console.log("%cHOWDY! 👋", style);

        // ---------------------------------------------------
        // ready
        // ---------------------------------------------------
        scrollFunction();

        // ---------------------------------------------------
        // scroll
        // ---------------------------------------------------
        function scrollFunction() {
            var windowScrollTop = $(window).scrollTop();
            if (windowScrollTop > 200) {
                header.addClass('scrolled');
                toTop.addClass('visible');
            } else {
                header.removeClass('scrolled');
                toTop.removeClass('visible');
            }

            //
            // side nav
            //
            if (sideNavMarker.length > 0) {
                if (windowScrollTop > (sideNavMarker.offset().top - 130)) {
                    sideNav.addClass('fixed');
                } else {
                    sideNav.removeClass('fixed');
                }
            }

            /**
             * count to
             *
             * @param options
             * @returns {*|define.amd.jQuery}
             */
            $.fn.countTo = function(options) {
                // merge the default plugin settings with the custom options
                options = $.extend({}, $.fn.countTo.defaults, options || {});

                // how many times to update the value, and how much to increment the value on each update
                var loops = Math.ceil(options.speed / options.refreshInterval),
                    increment = (options.to - options.from) / loops;

                return $(this).each(function() {
                    var _this = this,
                        loopCount = 0,
                        value = options.from,
                        interval = setInterval(updateTimer, options.refreshInterval);

                    function updateTimer() {
                        value += increment;
                        loopCount++;
                        $(_this).html(value.toFixed(options.decimals));

                        if (typeof(options.onUpdate) == 'function') {
                            options.onUpdate.call(_this, value);
                        }

                        if (loopCount >= loops) {
                            clearInterval(interval);
                            value = options.to;

                            if (typeof(options.onComplete) == 'function') {
                                options.onComplete.call(_this, value);
                            }
                        }
                    }
                });
            };

            $.fn.countTo.defaults = {
                from: 0,  // the number the element should start at
                to: 100,  // the number the element should end at
                speed: 1000,  // how long it should take to count between the target numbers
                refreshInterval: 100,  // how often the element should be updated
                decimals: 0,  // the number of decimal places to show
                onUpdate: null,  // callback method for every time the element is updated,
                onComplete: null,  // callback method for when the element finishes updating
            };

            //
            // home numbers
            //
            if (modHomeNumbers.length > 0 && isIntoView($('.mod-home-numbers')) && homeCountedUp === false) {
                var modHomeNo = $('.js-mod-home-number');
                modHomeNo.each(function () {
                    var thisMod = $(this);

                    thisMod.countTo({
                        from: 0,
                        to: thisMod.attr('data-number'),
                        speed: 1000,
                        refreshInterval: 50,
                    });
                });
                homeCountedUp = true;
            }
        }

        $(window).scroll(function () {
            scrollFunction();
        });

        // ---------------------------------------------------
        // hamburger
        // ---------------------------------------------------
        const hamburger = $('#hamburger');
        hamburger.click(function (e) {
            e.preventDefault();
            hamburger.toggleClass('no-hamburger');
            $('#nav-overlay').toggleClass('in');
            header.toggleClass('nav-overlay-in');
        });


        // ---------------------------------------------------
        // execute at document ready
        // ---------------------------------------------------
        $('#nav-overlay').find('.nav-block-title.w-icon').click(function () {
            $(this).parent().toggleClass('open');
        });



        // ---------------------------------------------------
        // count up home numbers
        // ---------------------------------------------------
        // function isIntoView(elem) {
        //     var documentViewTop = $(window).scrollTop();
        //     var documentViewBottom = documentViewTop + $(window).height();
        //
        //     var elementTop = $(elem).offset().top;
        //     var elementBottom = elementTop + $(elem).height();
        //
        //     return ((elementBottom <= documentViewBottom) && (elementTop >= documentViewTop));
        // }


        // ---------------------------------------------------
        // onepager navi
        // ---------------------------------------------------
        // $('.js-onepager-nav-item').click(function (e) {
        //     e.preventDefault();
        //     var scrollTarget = 0;
        //     var thisOnepagerLink = $(this).attr('data-onepager-link');
        //
        //     if ( thisOnepagerLink === 'top' ) {
        //         scrollTarget = 0;
        //     } else {
        //         scrollTarget = $('#' + thisOnepagerLink).offset().top;
        //     }
        //
        //     $('html, body').animate({ scrollTop: scrollTarget }, 300);
        // });


        // ---------------------------------------------------
        // borlabs cookie
        // ---------------------------------------------------
        // $('.js-cookie-box').click(function(e) {
        //     e.preventDefault();
        //     window.BorlabsCookie.showCookieBox();
        // });

    });
}(jQuery));
