$(document).ready(function() {
    $('.owl-carousel.owl-home-header').owlCarousel({
        items: 1,
        dots: true,
        loop: true,
        nav: false,
        autoplay: true,
        autoplaySpeed: 1000,
        autoplayTimeout: 8000,
    });
});
